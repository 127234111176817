.WorkflowForm {
  $default-light-color: lightgrey;
  $default-dark-color: #c2c2c2;
  $finished-color: #c29032;
  $finished-text-color: #ffffff;
  $current-color: #c2c2c2;
  $current-text-color: #c2c2c2;
  $icon-circle-size: 70px;
  $icon-text-size: 24px;
  $connector-size: 1.6px;
  $border-color: #d9d9d9;

  .my-workflow-quick-button {
    display: flex;
    flex-direction: row-reverse;
  }

  .saved-workflow-title {
    text-align: left;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    color: #132d5e;
    margin-left: 23%;
  }
  .add-workflow-title-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    button {
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 600;
      color: #132d5e;
      border: 1px dashed;
      span {
        margin-right: 10px;
      }
    }
  }

  .workflowName {
    margin: 15px 0px 20px 0px;
  }

  .col-sm-1.right-col {
    padding-right: 5px;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: small;
    color: #000000;
  }

  .btn1,
  .btn2,
  .btn3,
  .btn4 {
    height: 55px;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    font-size: small;
    background: #132d5e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .btn2 {
    background: #c29032 !important;
  }

  .btn3 {
    background: #535353 !important;
  }

  .btn4 {
    background: #e81e1e !important;
  }

  .form-control.shareEmail {
    width: 85%;
  }
  .tab_mob {
    display: none;
  }
  .pre_col {
    display: flex;
    flex-direction: column;
  }
  .doc-settings-below {
    .cust_toggle {
      width: 22%;
    }
    .lettertype {
      width: 30%;
    }
    .settingSub {
      .right-col {
        width: 10%;
        .btn1.pre_btn {
          span {
            display: none;
          }
          &:hover {
            span {
              display: none;
            }
          }
        }
      }
      .right-preview {
        width: 21%;
      }
    }
  }

  .docSettings {
    margin-top: 30px;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    .cust_toggle {
      width: 30%;
    }
    .letterweight {
      width: 20%;
    }

    .form-control {
      width: 100%;
    }

    .settingField {
      .switch {
        margin-top: 15px;
      }

      select {
        height: 55px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        background: #fff
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center;
        background-size: 10px 14px;
        border: 1px solid #ccc;
      }

      input {
        height: 55px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        background: #fff
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center;
        background-size: 10px 14px;
      }
    }

    .switch.mini {
      height: 24px;
      width: 50px;
      margin-top: 6px;
      margin-left: 20px;
    }

    .switch.mini .slider:before {
      height: 17px;
      width: 17px;
    }

    div > label:first-child {
      width: 100%;
      font-size: small;
      margin-top: 6px;
    }
  }

  .button-space {
    margin-bottom: 1rem;
    width: 74%;
  }

  @media (min-width: 768px) {
    .doc-settings-below {
      margin-top: 15px;
      margin-bottom: 10px;
      float: left;
      width: 100%;
    }

    .btn-success.btn.pull-right.desktop-button.a-doc {
      margin-right: 5px;
      height: 38px;
    }

    .btn-primary.btn.pull-right.a-doc {
      height: 38px;
    }

    .form-control {
      width: 100%;
    }

    .settingField {
      .switch {
        margin-top: 15px;
      }

      select {
        height: 55px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        background: #fff
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center;
        background-size: 10px 14px;
      }

      input {
        height: 55px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        background: #fff
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center;
        background-size: 10px 14px;
      }
    }

    .switch.mini {
      height: 24px;
      width: 50px;
      margin-top: 6px;
      margin-left: 20px;
    }

    .switch.mini .slider:before {
      height: 17px;
      width: 17px;
    }

    div > label:first-child {
      width: 100%;
      font-size: small;
      margin-top: 6px;
    }
  }

  .steps-cont {
    width: 100%;
    float: left;
    padding: 65px 0;
    background: #ffffff;
  }

  .flexbox-wizard {
    display: flex;
    justify-content: space-between;

    div {
      z-index: 0;
    }

    .wizard-step {
      text-align: center;
      flex: 1;
      position: relative;
      cursor: pointer;

      &::after,
      &::before {
        background: none;
        border-top: $border-color dotted 3px;
        z-index: 1;
        content: "";
        position: absolute;
        top: (($icon-circle-size - $connector-size) / 2);
        height: $connector-size;
        width: 30%;
      }

      &::before {
        right: 70%;
      }

      &::after {
        left: 70%;
      }

      .wizard-icon {
        background: #ffffff;
        // border: ($connector-size) solid $default-dark-color;
        color: darken($default-dark-color, 33%);
        border-radius: 50%;
        height: $icon-circle-size;
        width: $icon-circle-size;
        line-height: ($icon-circle-size - $connector-size);
        text-align: center;
        margin: auto;
        font-size: $icon-text-size;
        position: relative;
        z-index: 2;
        margin-bottom: 25px;
      }

      .title {
        font-size: small;
        color: #c2c2c2;
        font-weight: 600;
      }

      .description {
        font-size: small;
        font-weight: 600;
        color: $default-dark-color;
      }
    }

    .wizard-step:first-child {
      &::before {
        display: none;
      }
    }

    .wizard-step:last-child {
      &::after {
        display: none;
      }
    }

    .wizard-step.unfinished {
      .CircularProgressbar .CircularProgressbar-background {
        fill: #ffffff;
      }
      .CircularProgressbar .CircularProgressbar-path {
        stroke: #132d5e;
      }
    }

    .wizard-step.finished {
      &::after,
      &::before {
        // background: $finished-color;
      }

      .wizard-icon {
        color: $finished-text-color;
        border-color: $finished-color;
        background: $finished-color;
        &::after {
          content: "\F3FD";
          position: absolute;
          top: 0;
          font-family: Ionicons;
          left: 26px;
          font-size: 2em;
        }
      }
      .CircularProgressbar-text {
        fill: $finished-color !important;
      }
      .CircularProgressbar .CircularProgressbar-background {
        fill: $finished-color !important;
      }
      .CircularProgressbar .CircularProgressbar-trail {
        stroke: $finished-color !important;
      }
      .CircularProgressbar .CircularProgressbar-path {
        stroke: $finished-color !important;
      }
    }

    .wizard-step.current {
      &::before {
        // background: $finished-color;
      }

      .wizard-icon {
        color: $current-text-color;
        // border-color: $current-color;
        background: $current-color;
      }

      .wizard-content {
        .title {
          font-size: small;
          font-weight: 600;
          color: #000000;
        }

        .description {
          color: #000000;
          font-size: small;
          font-weight: 600;
        }
      }
      .CircularProgressbar-text {
        fill: #ffffff !important;
      }
      .CircularProgressbar .CircularProgressbar-background {
        fill: #132d5e;
      }
      .CircularProgressbar .CircularProgressbar-trail {
        stroke: #132d5e;
      }
      .CircularProgressbar .CircularProgressbar-path {
        stroke: #ffffff;
      }
    }
  }
  .ScrollUpButton__Container.ScrollUpButton__Toggled {
    display: none;
  }
  .WorkflowFormSteps {
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: auto;
    font-size: small;
    font-weight: 600;

    .number-div {
      width: 15%;
      float: left;
    }

    .name-div {
      width: 70%;
      float: left;
      margin-top: 5px;
    }

    .fieldsCount-div {
      width: 15%;
      float: left;
      margin-top: 5px;
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 1rem;
        cursor: pointer;
        width: 100%;
        float: left;

        &:hover {
          .name {
            opacity: 1;
          }
        }

        &.active {
          font-weight: 500;

          .number {
            background-color: #132d5e;
            color: #fff;
          }

          .name {
            opacity: 1;
          }
        }

        &.activeName {
          .number {
            background-color: #132d5e;
            color: #fff;
          }

          .name {
            color: #132d5e;
          }
        }

        .number {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: #eee;
          text-align: center;
          line-height: 2.2rem;
        }

        .name {
          // padding: 1rem;
          opacity: 0.7;
        }
      }
    }
    @media (max-width: 767x) {
      ul {
        $line_height: 20px;
        $padding: 9px;
        $arrow_size: ($line_height + 2 * $padding)/2;
        $max_li: 10;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
          "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana,
          "Verdana Ref", sans-serif;
        font-size: 13px;
        line-height: $line_height;
        font-weight: 600;
        counter-reset: li;
        display: block !important;

        li {
          list-style-image: none;
          list-style-type: none;
          margin-left: 0;
          text-align: center;
          color: #3a3a3a;
          background: #dae4eb;
          position: relative;
          margin-left: 5px + 2 * $arrow_size;
          height: 38px;
          padding-right: 12px;
          // Appended arrow.
          &:after {
            position: absolute;
            top: 50%;
            left: 100%;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border: solid transparent;
            border-left-color: #dae4eb;
            border-width: $arrow_size;
            margin-top: -$arrow_size;
          }

          span {
            display: block;
            padding: $padding ($padding + $arrow_size) $padding $padding;
            // Prepended arrow inset.
            &:after {
              position: absolute;
              top: 50%;
              right: 100%;
              content: " ";
              height: 0;
              width: 0;
              pointer-events: none;
              border: solid #dae4eb;
              border-left-color: transparent;
              border-width: $arrow_size;
              margin-top: -$arrow_size;
            }
            // "X)" numbering
            &:before {
              /*content: counter(li) ") ";
                                    counter-increment: li;*/
            }

            &.number {
              height: auto;
              width: auto;
              border-radius: 0;
              display: block;
              line-height: 1rem;
              padding: 0;
              background: transparent !important;
              color: #333 !important;
              line-height: 38px;
            }

            &.name {
              padding: 0;
              display: none;
            }
          }
        }

        & > li {
          float: left;
        }

        li.activeName {
          color: #fff;
          background: #132d5e;
          // Appended arrow.
          &:after {
            border-left-color: #132d5e;
          }

          .name {
            color: #fff;
            display: block;
          }

          .number {
            margin-top: 3px;
            color: #fff !important;
            line-height: 1rem;
          }

          span {
            // Prepended arrow.
            &:after {
              border-color: #132d5e;
              border-left-color: transparent;
            }
          }
        }
        // For the overlapping to work, later LIs must have a lower z-index.
        @for $i from 1 through $max_li {
          li.step#{$i} {
            z-index: $max_li - $i;
          }
        }
      }
    }
  }

  .step-forms-field-cont {
    width: 100%;
    float: left;
    background: #f2f2f2;
    padding: 60px 0;

    .WorkflowFormFields {
      // padding: 1rem 1.5rem 1rem;
      // background-color: #f7f8fa;
      // overflow: auto;

      input[type="radio"] {
        position: absolute;
        margin-left: -20px;
      }

      .ion-help-circled {
        color: #c29032;
      }

      .helper {
        font-size: small;
      }

      select::-ms-expand {
        display: none;
      }

      .option-menu-cont {
        float: right;
        display: none;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        background: #fff
          url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
          no-repeat right 0.75rem center;
        background-size: 10px 14px;
        border: 1px solid #ccc;
        padding-left: 11px;
      }

      .form-group {
        margin-bottom: 25px;
      }

      .input-group input:focus + .input-group-addon {
        color: #e81e1e !important;
      }

      .form-group {
        .input-group-addon {
          background: #fff;
          display: block;
          border: none;
          background: none;
          font-size: 19px;
          right: 20px;
          position: absolute;
          z-index: 5;
          top: 11px;
        }
      }

      .form-control {
        height: 55px;
        border-radius: 3px;
        padding-right: 35px;
      }

      .input-group {
        width: 100%;
        z-index: 0;
      }

      .input-group-addon {
        background: #fff;
        display: block;
        border: none;
        background: none;
        font-size: 19px;
        color: #d8d8d8;
        right: 20px;
        position: absolute;
        z-index: 5;
        top: 11px;
      }

      .buttons {
        .btn {
          margin-left: 1rem;
          float: right;
        }

        .btn-default {
          line-height: 28px;

          &:hover {
            i {
              margin-right: 10px;
            }
          }
        }
      }

      .title {
        font-size: 28px;
        margin-bottom: 1.5rem;
        color: #000000;
        font-weight: 600;
      }

      .boron-modal.helperText {
        font-size: 0.9rem;
      }

      .form {
        .checkbox,
        .form-group {
          position: relative;

          label {
            position: relative;
            padding-right: 1.2rem;
            font-weight: 600;
            font-size: small;
            color: #000000;
          }

          .helper {
            position: absolute;
            top: 0;
            right: 0;

            &.clickable {
              cursor: pointer;
            }
          }

          .warning {
            position: absolute;
            top: 0;
            right: 20px;

            &.clickable {
              cursor: pointer;
            }
          }

          .preview {
            position: absolute;
            top: 0;
            right: 40px;

            &.clickable {
              cursor: pointer;
            }
          }

          &.has-error {
            label {
              color: #a94442;
            }
          }

          &.has-dangerous {
            label {
              color: #d68f42;
            }

            input {
              border-color: #d68f42;
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            }
          }
        }

        .btn1,
        .btn2,
        .btn3 {
          height: 55px;
          border-radius: 3px;
          border: none;
          color: #ffffff;
          font-size: small;
          background: #132d5e;
          padding-left: 50px;
          padding-right: 50px;
        }

        .btn2 {
          background: #c29032;
        }

        .btn3 {
          background: #535353;
        }
      }

      .address-selector__control {
        border: none;
        box-shadow: none;
      }
      .address-selector__menu {
        left: 0px;
      }
    }
  }

  .WorkflowFormPreview {
    padding: 2rem 1.25rem 0;
    font-size: 0.9rem;
    border: 1px solid #cecece;
    border-radius: 3px;
    background: #ffffff;
    margin-top: 26px;

    .input-field {
      display: inline-block;
      min-width: 2rem;
      height: 1rem;
      background-color: #eee;
      text-align: center;
    }

    .panel-body {
      min-height: 500px;
    }

    .panel-default {
      background-color: #f7f8fa;
      overflow: auto;
      height: 100%;
    }

    .panel-body.docpreview {
      background: #fff;
      margin: 1rem;
      padding: 2rem;
      -webkit-box-shadow: 0 1px 15px 0 rgba(50, 50, 50, 0.1);
      -moz-box-shadow: 0 1px 15px 0 rgba(50, 50, 50, 0.1);
      box-shadow: 0 1px 15px 0 rgba(50, 50, 50, 0.1);
      position: relative;
      > div {
        > div {
          padding-bottom: 7px;
        }
      }
    }
    .docInnerWrapper {
      overflow: auto;
      pointer-events: none;
    }

    .doc-body {
      //border-top: 1px solid #ccc;
      margin-top: 1rem;
      padding-top: 2rem;
    }

    .doc-footer {
      background: #eee;
      padding: 1rem;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .highlight {
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: highlight;
      animation-name: highlight;
    }
    @keyframes highlight {
      0% {
        background-color: yellow;
      }
      100% {
        background-color: transparent;
      }
    }
    @-webkit-keyframes highlight {
      0% {
        background-color: yellow;
      }
      100% {
        background-color: transparent;
      }
    }

    .fadeLeft {
      border: #c29032 2px solid;
      padding: 5px;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;

      .highlight {
        -webkit-animation-name: none;
        animation-name: none;
      }
    }

    @-webkit-keyframes fadeInLeft {
      0% {
        -webkit-transform: translateX(100px);
      }
      99% {
        border: #c29032 2px solid;
        padding: 5px;
      }
      100% {
        -webkit-transform: translateX(0);
        border: none;
        padding: 0px;
      }
    }

    @keyframes fadeInLeft {
      0% {
        transform: translateX(100px);
      }
      99% {
        border: #c29032 2px solid;
        padding: 5px;
      }
      100% {
        -webkit-transform: translateX(0);
        border: none;
        padding: 0px;
      }
    }
  }

  .WorkflowFormFieldsContainer {
    .option-menu {
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      float: left;
      border-radius: 3px;
      padding: 9px 15px;
      border: none;
      width: 100%;
      font-weight: 400;
      background: #132d5e;

      i {
        font-size: 25px;
      }
    }

    .option-menu:hover {
      background: #000;
      color: #fff;

      i {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      min-width: 270px;
      font-size: 18px;
      padding: 20px;
    }

    .buttons {
      i {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 5px;

          .btn {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }

  .clausePreview {
    .content {
      text-align: left;
    }
  }

  .helperTextContent {
    overflow: auto;
    padding: 0;
    width: 100%;
    text-align: center;

    .helperText {
      text-align: left;
    }

    h2 {
      text-align: left;
    }

    .helperName b {
      font-weight: 500;
      color: #000000;
    }

    .btn {
      margin-right: 2%;
    }

    .input-field {
      display: inline-block;
      min-width: 2rem;
      height: 1rem;
      background-color: #eee;
      text-align: center;
    }
  }

  .form-modal {
    .add-btn {
      display: inline-block;
      width: 10%;
      margin-left: 3%;
      margin-top: -2px;
      margin-right: 0 !important;
      padding: 0 !important;
      height: 50px;
      font-size: 28px;
      border-radius: 3px;
      float: right;
    }

    .shareEmail {
      width: 85%;
      display: inline-block;
    }
  }
  .popover-rightside .close-btn {
    right: -30px !important;
    top: -30px !important;
  }

  .boron-modal,
  .modal_full {
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    // width: 600px !important;
    text-align: center;
    .close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
      color: #e81e1e;
    }

    .draftName {
      margin-bottom: 10px;
    }

    button {
      margin-top: 0px;
    }

    .btn {
      margin-right: 2%;
      i {
        margin-right: 10px;
      }
    }
  }

  .draft-reuse-modal {
    button {
      margin-top: 10px;
      margin-left: 5px;
    }
  }

  .boron-modal.download-modal > div:first-child {
    border-radius: 3px;
  }

  .boron-modal.latest-revision > div:first-child {
    border-radius: 3px;
  }

  .mr-25 {
    margin-right: 25px !important;
  }

  .mr-20 {
    margin-right: 2% !important;
  }

  .form-actions.download-cancel .btn {
    width: 49%;
  }

  .form-actions.download-cancel .btn.btn4 {
    margin-right: 0;
  }

  .preview-false .WorkflowFormFields .option-menu.btn.btn-default {
    display: none;
  }

  .up-false .WorkflowFormFields .option-menu.btn.btn-default {
    display: none;
  }

  .preview-true.up-true .right-menu {
    display: none;
  }

  .up-false .WorkflowFormFields.select-input-file {
    width: 65.6%;
  }

  .preview-false .WorkflowFormFields.select-input-file {
    width: 65.6%;
  }

  .form-group.WorkflowFormFields.select-input-file {
    width: 50%;
    margin-bottom: 2rem;
  }

  /*added today */
  .btn_rw_all {
    display: none;
  }
  .popover-rightside {
    position: absolute;
    right: -105px;
    top: 0px;
    z-index: 1;
  }
  .preview-true.up-false {
    .popover-rightside {
      display: none;
    }
  }
  .preview-false.up-true {
    .popover-rightside {
      display: none;
    }
  }

  /*added today */

  .btn.pull-right.button-space {
    height: 55px;
    font-size: small;
    font-weight: 600;
    text-align: left;
    background-color: white;
    border-color: #d9d9d9;
    color: #black;
  }

  a-doc {
    margin-left: 5px;
  }

  .preview-block {
    padding: 0 0 0 15px;
  }

  .buttons-doc .btn.pull-right.button-space {
    border-radius: 3px;
    padding: 0 10px;
    span {
      position: relative;
      left: -11px;
      display: inline-block;
      background: #e81e1e;
      height: 100%;
      padding: 16px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &.danger span {
      background: #e81e1e;
    }
    &.preview span {
      background: #535353;
    }
    &.save span,
    &.download span {
      background: #c29032;
    }
    span img {
      width: 17px;
    }
    &:hover {
      color: #333;
    }
  }

  .downloaded-doc {
    .btn-primary {
      padding: 4px 15px;
      height: 38px;
      margin-left: 10px;
      position: relative;
      top: 0px;
    }

    .css-1rzmley-container {
      .css-18xijp8-control {
        height: 38px !important;
        min-height: inherit !important;
        .css-gunuo7 {
          padding: 4px 8px;
        }
      }
    }
  }
  .settingSub {
    .settingField {
      .btn-success {
        width: 100%;
        padding: 16px 15px;
      }
      .btn-primary {
        width: 100%;
        padding: 16px 15px;
      }
    }
  }

  @media (max-width: 1400px) {
    .container-row {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .save-block {
    img {
      cursor: pointer;
      float: right;
      margin: 0 0 0 10px;
    }
  }

  button {
    &.btn-default {
      i {
        font-size: 20px;
      }
    }

    &.download {
      i {
        margin-right: 10px;
      }
    }

    &.fullwidth {
      i {
        margin-right: 10px;
      }
    }

    &.add-btn {
      margin-bottom: 3px;
      margin-top: 0;
    }
  }

  .force-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.6);
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    animation-name: anim_171533640449298;
    animation-timing-function: ease-out;
  }

  .timer {
    color: blue;
  }

  .step-completed {
    font-size: 1.4em;
    display: inline-block;
    vertical-align: middle;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .back-to-top {
    float: left;
    width: 100%;
    padding-bottom: 10px;
    margin-top: 0px;
  }

  .download-modal {
    .form-control {
      height: 50px;
      border-radius: 3px !important;
    }
    .borderBottom {
      border-bottom: 0.3rem solid #05a2f1 !important;
    }
    .attachWorkflowsList {
      display: flex;
      margin-top: 15px;
      flex-flow: wrap;
      max-height: 150px;
      overflow-y: scroll;
    }
    .label-content {
      border-radius: 0.3rem !important;
      background-color: #0ba1f1 !important;
    }
  }

  // style_custom1.css:
  .react-scrollbar-default .-reactjs-scrollbar-thumb {
    background: #4d7fff;
  }

  .react-scrollbar-default .-reactjs-scrollbar-track {
    background: rgba(0, 0, 0, 0.3);
  }

  .react-scrollbar-default .-reactjs-scrollbar-track\:vertical {
    width: 5px;
  }

  .share-email-link {
    text-decoration: none;
    color: inherit;
    opacity: 0.8;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .preview-below {
      margin-top: 150px;
      padding: 0 6px 0 15px;
    }
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .WorkflowForm {
    .doc-settings-below {
      margin-top: 15px;
      margin-bottom: 15px;
      float: none;
      width: 100%;
      display: inline-block;
      .cust_toggle {
        width: 25%;
        padding-right: 0;
      }
      .lettertype {
        width: 23%;
        padding-right: 0;
      }
      .letterweight {
        width: 19%;
        padding-right: 0;
      }
      .settingSub {
        float: left;
        width: 33%;
        .right-preview {
          width: 65%;
          padding-right: 0;
        }
        .right-col {
          width: 35%;
        }
      }
    }
    .docSettings {
      .cust_toggle {
        width: 35%;
      }
      .letterweight {
        width: 23%;
      }
    }
    .button-space {
      margin-bottom: 1rem;
      width: 88%;
    }
    .preview-below {
      margin-top: 30px;
      padding: 0 6px 0 15px;
    }
    .preview-block {
      .WorkflowFormPreview {
        margin-top: 18px;
      }
    }
    .preview-block.preview-below {
      .WorkflowFormPreview {
        margin-top: 34px;
      }
    }
  }
}

@media (min-width: 771px) and (max-width: 990px) {
  .popover.left {
    margin-left: 41px;
    margin-top: 0;
  }
  .buttons-doc {
    .btn.pull-right.button-space {
      border-radius: 3px;
      padding: 0 10px;
      width: auto;
      float: left !important;
      margin-right: 15px;
    }
  }
  .WorkflowForm {
    .doc-settings-below {
      margin-top: 18px;
      margin-bottom: 38px;
      float: left;
      width: 100%;
      .lettertype {
        width: 28%;
      }
      .cust_toggle {
        width: 23%;
      }
      .right-preview {
        display: none;
      }
    }
    .preview-below {
      margin-top: 25px;
      padding: 0 6px 0 15px;
      clear: both;
    }
    .preview-false {
      .WorkflowFormFields {
        .option-menu.btn.btn-default {
          display: block;
        }
      }
    }
    .preview-true.up-true {
      .right-menu {
        display: block;
      }
    }
    .up-false {
      .WorkflowFormFields {
        .option-menu.btn.btn-default {
          display: block;
        }
      }
    }
  }
  .WorkflowFormFieldsContainer {
    .right-menu {
      display: none;
    }
  }

  .WorkflowForm {
    .docSettings {
      .cust_toggle {
        width: 37%;
      }
      .lettertype {
        width: 35%;
        padding-left: 0;
      }
      .letterweight {
        width: 28%;
        padding-right: 0;
        padding-left: 0;
      }
      label {
        font-size: 11.5px !important;
      }
    }
    .step-forms-field-cont {
      .WorkflowFormFields {
        .form {
          .checkbox {
            label {
              position: relative;
              padding-right: 2.5rem;
              font-weight: 600;
              font-size: small;
              color: #000;
              padding-top: 0px;
              margin-top: 0;
            }
          }
          .form-group {
            label {
              position: relative;
              padding-right: 2.5rem;
              font-weight: 600;
              font-size: small;
              color: #000;
              padding-top: 0px;
              margin-top: 0;
            }
          }
        }
      }
    }
    .doc-settings-below {
      .settingSub {
        .right-col {
          .btn1.pre_btn {
            span {
              display: block;
            }
            &:hover {
              span {
                display: block;
              }
            }
            i {
              display: none;
            }
            margin-top: 11px;
          }
          label {
            display: none;
          }
          width: 18%;
        }
      }
    }
  }
}

@media (max-width: 770px) {
  .buttons-doc {
    display: none;
  }
  .btn-success.btn.pull-left.desktop-button {
    display: none;
  }
  .btn-success.btn.pull-right.desktop-button {
    display: none;
  }
  .WorkflowFormFieldsContainer {
    .right-menu {
      display: none;
    }
  }
  .WorkflowForm {
    .settingField {
      .switch {
        margin-top: 2px !important;
      }
    }
  }
  .WorkflowForm {
    .ScrollUpButton__Container.ScrollUpButton__Toggled {
      display: block;
      outline: none;
      right: 50px !important;
      svg:focus {
        outline: none;
        border: none;
      }
    }
  }
  .WorkflowFormPreview {
    padding: 1px 1px 1px 1px;
  }
}

@media (min-width: 1200px) {
  .boron-modal {
    width: 600px !important;
  }
}

.popover {
  border-radius: 3px !important;
  padding: 20px;
  max-width: 300px;
  font-family: Montserrat, sans-serif;
}
.popover.left {
  margin-left: 178px;
  margin-top: 0px;
}
.popover.top {
  margin-top: 3px;
}
.popover {
  z-index: 1;
  .buttons-doc.menu-opt {
    position: relative;
    top: 9px;
    .btn.pull-right.button-space {
      border-radius: 3px;
      padding: 0 10px;
      background: #fff;
      border: 1px solid #d9d9d9;
      margin-bottom: 11px;
      width: 100%;
      text-align: left;
      span {
        position: relative;
        left: -11px;
        display: inline-block;
        background: #e81e1e;
        height: 100%;
        padding: 16px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &.danger span {
        background: #e81e1e;
      }
      &.preview span {
        background: #535353;
      }
      &.save span,
      &.download span {
        background: #c29032;
      }
      span img {
        width: 17px;
      }
      &:hover {
        color: #333;
      }
    }
  }
  .popover-content {
    padding: 0;
    .close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
      color: #e81e1e;
      i {
        margin-right: 0px;
        font-size: 34px;
      }
    }
  }

  .arrow {
    top: 12% !important;
    display: none;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      font-size: small;
      padding: 0 0 15px;

      a {
        color: #000000;
      }

      li:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.address-selector {
  white-space: pre-line;
  text-align: left !important;
}
.select-doc {
  display: flex;
  margin-right: 0px;
  margin-bottom: 10px;
  .select-type.select-doc {
    margin-right: 13px;
    height: 38px !important;
  }
}
.select-type {
  display: block;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  height: 55px;
  border-radius: 3px;
  padding-right: 35px;
}

.select-type.select-doc {
  width: 38%;
}

.get-info {
  width: auto !important;
}

.fas.fa-magic {
  font-size: 25px;
  padding: 10px 10px 10px 10px;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 770px) {
  .modal_full {
    background: #fff;
    padding: 20px;
    width: 94% !important;
    max-width: 500px;
    height: 92%;
  }
  .modal_full button.btn {
    background: none;
    padding: 0 !important;
    float: right;
  }
  .modal_full button.btn:hover {
    background: none;
  }
  .modal_full button.btn span.ion-android-cancel {
    display: inline-block;
    font-size: 24px;
    padding: 6px 8px;
  }
  .WorkflowForm {
    .preview-false.up-true {
      .popover-rightside {
        display: block;
      }
    }
    .preview-true.up-false {
      .popover-rightside {
        display: block;
      }
    }
    .buttons-doc {
      .btn.pull-right.button-space {
        border-radius: 3px;
        padding: 0 10px;
        width: 100%;
        max-width: 100%;
        span {
          left: 0px;
          margin-right: 7px;
        }
      }
    }
    .btn_rw_all {
      display: block;
    }
    .docSettings {
      margin-top: 30px;
      margin-bottom: 10px;
      float: left;
      width: 100%;
      text-align: left;
    }
    .modal_full {
      .docSettings {
        .cust_toggle {
          width: 100%;
          margin: 0;
          padding: 0;
        }
        .lettertype {
          width: 100%;
          margin: 0;
          padding: 0;
        }
        .letterweight {
          width: 100%;
          margin: 0;
          padding: 0;
        }
        .settingSub {
          .right-col {
            width: 100%;
            margin: 0;
            padding: 0;
          }
          .btn.pull-right.btn1 {
            width: 100%;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
  .btn_rw_all {
    margin: 20px 0px 0;
  }
  .WorkflowForm .popover-rightside {
    position: relative;
    right: 0;
    top: 0;
    z-index: 1;
    .btn-toolbar {
      display: none;
    }
  }

  .btn_full {
    button.option-menu.opt {
      background: #132d5e;
      color: #fff;
      width: 100%;
      font-weight: 400;
      border-radius: 4px;
      font-size: small;
      &:hover {
        color: #fff;
      }
    }
  }
  .btn_rw_all {
    .buttons-doc {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .WorkflowForm {
    .doc-settings-below {
      .lettertype {
        width: 26%;
        padding-right: 0;
        padding-left: 0;
      }
      .letterweight {
        width: 22%;
        padding-right: 0;
      }
      .settingSub {
        .right-col {
          .btn1.pre_btn {
            span {
              display: block;
            }
            &:hover {
              span {
                display: block;
              }
            }
            i {
              display: none;
            }
          }
        }
      }
    }
  }

  .desk_vw {
    display: none;
  }

  .docSettings {
    z-index: 99;

    .settingField {
      width: 100%;
      float: left;
      margin-bottom: 20px;
    }
  }

  .form {
    .checkbox,
    .form-group {
      position: relative;

      label {
        padding-right: 55px !important;
      }
    }
  }

  .WorkflowForm {
    .pre_col {
      .preview-block {
        margin-top: 10px;
      }
    }
    .steps-cont {
      padding: 0px;
    }
    .back-to-top {
      margin-top: 0px;
      .tab_mob {
        float: left;
        width: 79%;
        text-align: left;
        display: inline-block;
        input {
          height: 37px !important;
        }
        select {
          height: 37px !important;
        }
        .docSettings {
          width: auto;
          float: none;
        }
      }
      .settingField.mob {
        margin-top: 62px;
        float: left;
        display: none;
      }
      .lower_part {
        .settingField.mob {
          margin-top: 0px;
          float: left;
          display: block;
        }
      }
    }
    .panel-body.docpreview {
      margin: 0rem !important;
      padding: 1rem !important;
    }
    .wizard-icon {
      height: 48px !important;
      width: 48px !important;
      line-height: 48px !important;
    }
    .wizard-icon:after {
      left: 18px !important;
      font-size: 1.5em !important;
    }
  }

  .WorkflowForm {
    .flexbox-wizard {
      .wizard-step {
        &:after {
          top: 22.2px !important;
        }
        &:before {
          top: 22.2px !important;
        }
      }
    }
  }

  .docSettings {
    z-index: 99;
    .settingField {
      width: 100%;
      float: left;
      margin-bottom: 20px;
    }
  }
  .popover.left {
    margin-top: 46px;
  }
  .preview-block {
    padding: 0;
    margin-top: 71px;
    padding: 0;
    margin-top: 71px;
    clear: both;
  }

  .buttons-doc {
    .btn.pull-right.button-space {
      margin-right: 14px;
      max-width: 262px;
      width: auto;
      float: left !important;
      background: #fff;
      font-weight: 600;
      margin-bottom: 11px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-color: #d9d9d9;
    }
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: inline-block;
    width: 100%;
  }
  .WorkflowForm {
    .preview-below {
      margin-top: 0;
      padding: 0 15px;
      display: inline-block;
      clear: both;
    }
    .prev_blockdv {
      .WorkflowFormPreview {
        margin-top: 1px;
        padding: 1px 1px 1px 1px;
      }
    }
    .WorkflowFormPreview {
      margin-top: 28px;
      padding-bottom: 1px;
      padding: 1px 1px 1px 1px;
    }
    .preview-false {
      .WorkflowFormFields.select-input-file {
        width: 98%;
      }
      .WorkflowFormFields {
        .option-menu.btn.btn-default {
          display: block;
        }
      }
    }
    .up-false {
      .WorkflowFormFields.select-input-file {
        width: 98%;
      }
      .WorkflowFormFields {
        .option-menu.btn.btn-default {
          display: block;
        }
      }
    }
    .docSettings {
      .letterweight {
        width: 29%;
        padding-right: 0;
      }
      .lettertype {
        width: 37%;
        padding-right: 6px;
      }
      .cust_toggle {
        width: auto;
        padding-right: 0;
        float: left;
        padding-left: 3px;
      }
    }
    .doc-settings-below {
      .cust_toggle {
        width: auto;
        float: left;
      }
      .settingSub {
        width: 22%;
        float: left;
        .right-preview {
          width: 21%;
          display: none;
        }
        .right-col {
          width: 100%;
          .btn1.pre_btn {
            margin-top: 6px;
          }
          label {
            display: none;
          }
        }
        .btn.pull-right.btn1 {
          padding: 8px 15px;
        }
      }
      select {
        height: 37px;
      }
      input {
        height: 37px;
      }
    }
    .preview-block {
      padding: 0 0 0 0px;
    }
    .preview-true.up-true {
      .right-menu {
        display: block;
      }
    }
  }
  .settingSub {
    .col-sm-2 {
      width: 28%;
    }
    .col-sm-1.right-col {
      width: 10%;
    }
  }
  .col-sm-6.cust_pre_bx {
    width: 100%;
    clear: both;
  }
  .col-sm-6.preview-true.up-true {
    width: 100%;
    clear: both;
  }

  .settingField {
    select {
      height: 55px;
      border-radius: 3px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      background: #fff
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center;
      background-size: 10px 14px;
      border: 1px solid #ccc;
    }

    input {
      height: 55px;
      border-radius: 3px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      background: #fff
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center;
      background-size: 10px 14px;
    }
  }
  .popover {
    max-width: 270px;
    width: 75%;
    right: 14px;
    @if (".labelPopover") {
    } @else {
      left: auto !important;
    }
    .close-btn {
      float: left;
      width: 100%;
      text-align: right;
      font-size: 20px;
    }
    .arrow {
      display: none;
    }
  }
  .btn1,
  .btn2,
  .btn3,
  .btn4 {
    height: 35px !important;
    font-size: small !important;
    line-height: 2px;
  }
  .popover {
    @if (".labelPopover") {
    } @else if (".commentPopover") {
    } @else {
      top: 706px !important;
    }
    @if (".commentPopover") {
    } @else if (".labelPopover") {
    } @else {
      top: 706px !important;
    }
  }
  .WorkflowFormFieldsContainer > .col-md-8 {
    padding: 0 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .WorkflowForm {
    .back-to-top {
      .tab_mob {
        float: left;
        width: 100%;
        text-align: left;
        display: inline-block;
      }
      .settingField.mob {
        margin-top: 0px;
        float: right;
      }
    }
    .doc-settings-below {
      .lettertype {
        width: 35%;
      }
      .settingSub {
        label {
          display: none;
        }
        width: 100%;
        float: none;
        margin-top: 14px;
        .btn.pull-right.btn1 {
          padding: 8px 15px 10px 15px;
          width: 109px;
        }
        .right-col {
          width: 100%;
          clear: both;
        }
      }
    }
  }
}

.btn_full.preview button.option-menu.opt {
  background-color: #c29032;
}

@media (max-width: 480px) {
  .buttons-doc {
    .btn.pull-right.button-space {
      margin-right: 14px;
      max-width: 100%;
      width: 100%;
      float: left !important;
      background: #fff;
      text-align: left;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 13px;
      cursor: pointer;
      font-weight: 600;
      border-color: #d9d9d9;
    }
  }

  .doc-settings-below {
    display: flex;
    flex-wrap: wrap;
    select.form-control {
      height: 38px;
    }
    input {
      height: 38px;
    }
  }
  .WorkflowForm {
    .back-to-top {
      .tab_mob {
        display: inline-block;
        float: left;
        width: 100%;
        text-align: left;
      }
      .settingField.mob {
        margin-top: 0px;
        width: auto;
        float: right;
      }
    }
    .doc-settings-below {
      .settingSub {
        width: 100%;
        .right-preview {
          display: none;
        }
        .right-col {
          width: auto;
          float: right;
          margin-right: 0px;
          label {
            display: none;
          }
        }
      }
      .cust_toggle {
        width: 35%;
        float: left;
        margin-bottom: 12px;
        padding-right: 0;
      }
      .lettertype {
        width: 36%;
        float: left;
        margin-bottom: 12px;
        padding-right: 0;
      }
      .letterweight {
        width: 27%;
        float: left;
        margin-right: 0;
        padding-right: 0;
      }
      label {
        font-size: 11px !important;
      }
    }
    .preview-false {
      .WorkflowFormFields.select-input-file {
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
      }
      .WorkflowFormFields {
        .option-menu.btn.btn-default {
          display: block;
        }
      }
    }
    .up-false {
      .WorkflowFormFields.select-input-file {
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
      }
      .WorkflowFormFields {
        .option-menu.btn.btn-default {
          display: block;
        }
      }
    }
    .preview-true.up-true {
      .right-menu {
        display: block;
      }
    }
    .preview-block {
      padding: 0 0px;
    }
    .preview-block.preview-below {
      padding: 0 0px;
    }
    .up-true {
      .docSettings {
        .col-sm-2 {
          width: 20%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .docSettings {
      .settingField {
        label {
          font-size: 11px !important;
        }
      }
      .cust_toggle {
        width: 35%;
        padding-left: 3px;
      }
      .lettertype {
        width: 39%;
        padding-left: 0;
        padding-right: 15px;
      }
      .letterweight {
        width: 22%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .WorkflowFormFieldsContainer {
    .right-menu {
      display: none;
    }
  }

  .popover {
    max-width: 270px;
    width: 75%;
    .close-btn {
      float: left;
      width: 100%;
      text-align: right;
      font-size: 20px;
    }
    .arrow {
      display: none;
    }
  }
  .popover.top {
    @if (".labelPopover") {
    } @else {
      margin-top: -43px;
    }
  }
  .popover.left {
    margin-top: 173px;
  }
  .WorkflowForm {
    .doc-settings-below {
      .settingSub {
        .btn.pull-right.btn1 {
          padding: 8px 15px !important;
        }
        .right-col {
          br {
            display: none;
          }
        }
      }
    }
  }
  .settingField.mob {
    a.btn {
      font-size: 13px;
      padding: 8px 15px !important;
    }
  }
}
