.inner-page-cont {
  background-color: #f9f9f9;
}

.DocCategoryList {
  .flip-container a:hover {
    background-color: #15375c;
    color: white;
    i {
      color: #fff;
    }
  }

  i.folder-icon {
    font-size: 120%;
    margin-right: 10px;
  }

  .flip-container {
    display: inline-block;
    perspective: 1000px;
    height: 230px;
    width: 33.33%;
  }

  @media (max-width: 813px) {
    .flip-container {
      display: inline-block;
      perspective: 1000px;
      width: 50%;
      margin-bottom: 14px;
    }

    .back {
      padding: 8px;
    }
  }

  @media (max-width: 600px) {
    .flip-container {
      display: inline-block;
      perspective: 1000px;
      width: 100%;
      margin-bottom: 14px;
    }
    .back {
      padding: 10px;
    }
  }

  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    // top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
  }

  .front a {
    text-align: right;
    i {
      width: 30px;
      margin-top: 12px;
      font-size: 24px;
      color: #ccc;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .front {
    z-index: 2;
  }

  .back {
    transform: rotateY(180deg);
    z-index: 10;
  }

  .rotate {
    transform: rotateY(180deg);
  }

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    // background: #f35e59;
    position: relative;
    width: 100%;
  }

  .back {
    background: #15375c;
    border-radius: 3px;
    height: 220px;
    width: 100%;
    border: #15375c solid 1px;
    z-index: 20;

    .stats-table {
      font-size: 14px;
      color: #ffffff;
      td {
        padding-bottom: 7px;
        text-align: left;
      }
    }

    .sats-option {
      width: 100px;
    }
    .spe {
      width: 40px;
      text-align: center;
    }
    p {
      font-weight: 500;
      color: #ffffff !important;
      text-align: left;
      font-size: 14px;
      &.stats-heading {
        font-weight: 700;
        border-bottom: #ffffff solid 1px;
        padding-bottom: 14px;
        margin-bottom: 16px;
        font-size: 16px;
      }
      &.closeButton {
        float: right;
        cursor: pointer;
        margin: 0px 0px 0;
        border: 1px solid #fff;
        padding: 2px;
        text-align: center;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
  }


  div.flipper {
    a {
      background-color: #fff;
      color: #151515;
      position: absolute;
      display: block;
      width: 100%;
      padding: 1.2rem;
      text-decoration: none;
      text-align: left;
      font-weight: 700;
      border: 2px solid #d4d4d4;
      border-radius: 3px;
      font-size: 1rem;
      height: 220px;
      top: 0;
      left: 0;
    }

    a:hover {
      border-color: #132d5e;
      background-color: #132d5e;
      border-radius: 3px;

      color: #fff;
      .categoryImage {
        filter: brightness(30);
      }
    }

    a {
      .cate-name {
        margin-top: 19px;
      }
      .categoryImage {
        position: relative;
        margin: 10px auto 0;
        width: 120px;
        height: 120px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
      }

      p {
        text-align: center;
        margin-top: 0px;
        // color: #3399ff;
        font-weight: 500;
      }
    }
  }


  @media (min-width: 600px) {
    .front, .back {
      width: calc(100% - 20px);
      left: 10px;
    }
  }
}